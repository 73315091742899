import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import '../sass/main.scss';

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title={data.wordpressPage.yoast.title} description={data.wordpressPage.yoast.metadesc} />
    <h1>Posts</h1>
      {data.allWordpressPost.edges.map(({ node }) => (
        <div key={node.slug}>
          <Link to={node.slug} css={{ textDecoration: `none` }}>
            <h3>{node.title}</h3>
          </Link>
          <div dangerouslySetInnerHTML={{ __html: node.excerpt }} className="excerpt" />
          <div dangerouslySetInnerHTML={{ __html: node.acf.summary }} />
        </div>
      ))}
  </Layout>
)

export default IndexPage

export const pageQuery = graphql`
  query {
    wordpressPage(path: {eq: "/"}) {
      id
      yoast {
        title
        metadesc
        metakeywords
      }
    }
    allWordpressPost(sort: { fields: date, order: DESC }) {
      edges {
        node {
          title
          excerpt
          slug
          acf {
            summary
          }
        }
      }
    }
  }
`
